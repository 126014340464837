/**
 * Created by Administrator on 2019/10/7.
 */
import { request } from "../utils/request";
import serviceInterface from "../api/serviceInterface";

/**
 * 逐个搜索图片ID
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const searchImage = (params) => {
  return request({
    url: serviceInterface.downLoad.searchImage,
    params: params,
    timeout: 30000,
  });
};

/**
 * 逐个搜索图片ID
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const freeSearchImage = (params) => {
  return request({
    url: serviceInterface.downLoad.freeSearchImage,
    params: params,
    timeout: 15000,
  });
};

/**
 * 判断账户余额是否足以购买图片
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const hasBlance = (params) => {
  return request({
    url: serviceInterface.downLoad.hasBlance,
    params: params,
    timeout: 90000,
  });
};

/**
 * 进行图片下载，并生产订单
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const buy = (params) => {
  return request({
    url: serviceInterface.downLoad.buy,
    params: params,
    timeout: 300000,
  });
};

/**
 * 进行图片下载，并生产订单--等待模式
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const buyWait = (params) => {
  return request({
    url: serviceInterface.downLoad.buyWait,
    params: params,
    timeout: 10000,
  });
};

/**
 * 进行图片下载，并生产订单--等待模式
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const check = (params) => {
  return request({
    url: serviceInterface.downLoad.check,
    params: params,
    timeout: 5000,
  });
};

/**
 * 进行图片下载，并生产订单
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const buyTemp = (params) => {
  return request({
    url: serviceInterface.downLoad.tempbuy,
    params: params,
    timeout: 300000,
  });
};

/**
 * 进行图片重新下载
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const reDownload = (params) => {
  return request({
    url: serviceInterface.downLoad.reDownload,
    params: params,
  });
};

/**
 * 获取各素材网站费用明细
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const chargesList = (params) => {
  return request({
    url: serviceInterface.brand.chargesList,
    params: params,
  });
};

/**
 * 次卡登陆接口
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const tempLogin = (params) => {
  return request({
    url: serviceInterface.downLoad.tempLogin,
    params: params,
  });
};

/**
 * 获取shutter状态
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const getShutterStatus = (params) => {
  return request({
    url: serviceInterface.downLoad.getStatus,
    params: params,
  });
};
